
.header-div {
    margin: 1rem;;
}

.header {
    font-size: 1.5rem;
    font-family: zenon, sans-serif;
    font-style: italic;
    font-size: 70px;
    font-weight: 300;
}

.subpage {
    max-width: 600px;
    text-align: left;
    padding-left: 20px;
    border-left: 2px solid var(--main-color);
    margin-top: 0;
}

.contact-body {
    display: flex;
    flex-direction: column;
    font-size: 1.4rem;
    text-align: center;
    align-items: center;
    background-color: #fde4c6;
    padding-top: 30px;
    padding-bottom: 30px;
}

.contact-element {
    display: grid;
    grid-template-columns: 20% 80%;
    margin: 0.7rem;
    width: 40rem;
}

.contact-element input{
    width: 15rem;
}

.contact-input {
    /* width: 20rem; */
    margin-left: 1rem;
    max-width: 50rem;
    font-size: 1rem;
    text-align: center;
    border-width: 0px;
}

.contact-input-message{
    width: 30rem;
    /* max-width: 50rem; */
    margin-left: 1rem;
    height: 15rem;
    font-size: 1rem;
    text-align: center;
    border-width: 0px;
}

@media screen and (max-width: 960px) {

    .header {
        font-size: 52px;
    }

    .contact-element {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .contact-element input{
        width: revert;
    }

    .contact-input {
        margin-left: 1rem;
        margin-right: 1rem;
        max-width: auto;
        height: 2rem;
    }

    .contact-input-message{
        margin-left: 1rem;
        margin-right: 1rem;
        width: revert; 
    }
}

