.footer {
    background-color: #f0f0f0;
    padding: 20px;
  }
  
  .footer h5 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .footer p {
    font-size: 14px;
    line-height: 1.5;
  }
  
  .footer ul {
    list-style: none;
    padding: 0;
  }
  
  .footer li {
    margin-bottom: 5px;
  }
  
  .footer .social-icons {
    display: flex;
    justify-content: space-between;
  }
  
  .footer .social-icons a {
    color: #333;
    font-size: 20px;
  }
  
  .footer .copyright-info {
    margin-top: 20px;
    text-align: center;
  }
  
  .footer .copyright-info p {
    font-size: 12px;
    color: #999;
  }

  .footer .container {
    display: grid;
  }