.products {
    align-content: center;
    justify-content: center;
}

.product-list {
    list-style: none;
    display: inline-flex;
    flex-wrap: wrap;
}

.product-item {
    padding: 0rem 1rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
}

.product-item li{
    height: 20rem;
    width: 20rem;
    border-style: solid;
    border-width: 1px;
    border-color: black;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    box-shadow: 5px 5px 10px -3px var(--main-color);
}

.product-name {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.product-img {
    max-width: 100%;
    max-height: 100%;
    align-self: center;
    margin: auto;
}

.product-img2 {
    width: 20rem;
}

.popup-buttons {
    width: 20rem;
}

.product-title {
    font-size: 1rem;
    text-align: center;
}
.product-price {
    font-size: 1rem;
    text-align: center;
}

.product-card {
    width: 100%;
    aspect-ratio: 1 / 1;
    /* padding: none; */
}

@media screen and (max-width: 960px) {

    .product-list {
        display: flex;
        flex-wrap: wrap;
    }

    .product-item {
        width: 50%;
    }

    .product-item li{
        height: 10rem;
        width: 10rem;
    }
}