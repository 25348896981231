
.thank-message {
    color: #dd9787; 
    text-align: center;
    font-family: bistro-script-web,sans-serif;
    font-style: normal;
    font-size: 2rem;
    line-height: 3rem;
}

.thank-body {
    margin-top: 10px;
    padding: 10px;
    text-align: center;
    font-family: zenon, sans-serif;
    font-weight: 200;
    font-style: normal;
    font-size: 1.5rem;
    line-height: 2.5rem;
}

p {
    margin-top: 10px;
}