* {
  box-sizing: border-box;
  margin: 0 ;
  padding: 0;
}

a {
  text-decoration: none;
  color: #000
}

p {
  font-family: zenon, sans-serif;
}

:root {
  --navbar-height: 80px;
  --main-color: #dd9787;
}