.MainNavBar {
    background: #f6e7cb;
    height: var(--navbar-height);
    display: grid;
    grid-template-columns: 25% 50% 25%;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: fixed;  
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    box-shadow: 0px 5px 10px -3px var(--main-color);
}

.div-logo {
    grid-column-start: 1;
    width:100%;
    display:flex;
    justify-content:center;
}

.navbar-logo {
    grid-column-start: 1;
    margin-left: 1rem;
    margin-right: 1rem;
    cursor: pointer;
    height: 3em;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    list-style: none;
    text-align: center;
    font-family: zenon, sans-serif;
    margin-bottom: 0;
}

.nav-links {
    color: #4c956c;
    text-decoration: none;
}

.nav-links:hover {
    background-color: #a6c48a;
    border-radius: 4px;
}

.nav-links-mobile {
    display: none;
}

.fa-bars {
    color: var(--main-color);
}

.fa-times {
    color: #614524;
}

.menu-icon {
    display: none;
}

.button-link {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}

@media screen and (max-width: 960px) {
    .MainNavBar {
        grid-template-rows: 10% 90%;
    }

    .div-logo {
        grid-column-start: 2;
    }

    .nav-menu {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 1;
        align-self: start;
        visibility: hidden;
        display: flex;
        padding: 0;
        flex-direction: column;
        width: 100%;
        height: 0;
        z-index: 1;
        transition: height 0.5s ease-out;
    }

    .nav-menu.active {
        background: #a6c48a;
        justify-content: center;
        visibility: visible;
        height: 400px;
    }
    
    .navbar-logo {
        grid-row-start: 2;
        grid-column-start: 2;
        height: 2rem;
    }

    .navbar-logo.active {
        display: none;
    }

    .menu-icon {
        /* grid-row-start: 1; */
        grid-column-start: 3;
        margin-top: 2rem;
        margin-right: 2rem;
        height: 100%;
        display: flex;
        justify-self: flex-end;
        font-size: 1.8rem;
        z-index: 2;
    }

    .nav-links {
        text-align: center;
        color: #614524;
        padding: 1rem 100px;
        font-size: 2rem;
        perspective: 100px;
    }

    .nav-links:hover {
        background-color: #f6e7cb;
        border-radius: 4px;
    }

    .nav-menu li{
        visibility: hidden;
        transition: padding 0.75s ease-in;
    }

    .nav-menu.active li{
        visibility: visible;
        padding: 1rem 0rem;
    }
}


/*

    .menu-icon {
        grid-row-start: 1;
        grid-column-start: 3;
        justify-self: end;
        margin-right: 1rem;
        display: block;
        font-size: 1.8rem;
        cursor: pointer;
        position:absolute;
        z-index: 2;
    }
*/