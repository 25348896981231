.product-page {
    background-color: white;
    margin: 0.5rem;
    padding: 0.5rem;
}

.product-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 20px;
    justify-content: center;
}

.product-price-whole {
    font-size: 1.2rem;
}

.product-message {
    font-family: zenon, sans-serif;
}

.product-price-fraction {
    position: relative;
    top: -.45em;
    font-size: 12px;
}

.product-price-sign {
    position: relative;
    top: -.45em;
    font-size: 12px;
}

.PageControl {
    font-size: 1.2rem;
    margin-left: 30px;
    margin-right: 30px;
}

.JumpControl {
    font-size: 1.2rem;
    margin-right: 20px;
}

/* .product-card:hover::after {
    content: attr(title);
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 5px;
    border-radius: 5px;
    font-size: 0.875rem;

    z-index: 10;
} */

.product-image {
    width: 100%;
    height: 100%;
    /* width: 200px;
    height: 200px; */
    object-fit: cover;
}

.control-btn {
    width: 100px !important;
}

@media (max-width: 600px) {
    .product-list {
        gap: 5px;
    }

    .control-btn {
        width: 50px !important;
    }
    /* .product-image {
        width: 170px;
        height: 170px;
    } */
}