
.test {
    display: grid;
    grid-template-columns: 25% 50% 25%;
}

.Header-Img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
}

.MediaLink {
    margin: 1rem 0;
    width: 100%;

}

.MediaLink a{
    text-decoration: none;
    width: 100%;
}

.Logo {
    height: 15rem;
    z-index: 1;
    margin-top: 20px;
}

.Slogan {
    z-index: 1;
    /* margin-top: 1rem; */
    margin-bottom: 1rem;
}

.Slogan h1{
    z-index: 1;
    color: #dd9787; 
    text-align: center;
    font-family: bistro-script-web,sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 3rem;
    line-height: 2.5rem;
}

.LinkBtnImg {
    height: 3.5rem;

}

.LinkBtnTitle {
    width: 100%;
    font-size: 2.5rem;
    padding-right: 1rem;
    color: #f6e7cb;
    
}

.filtered-products {
    display: grid;
    grid-template-columns: repeat(6, minmax(150px, 1fr));
    gap: 20px;
    justify-content: center;
    max-width: 100%;
    padding: 1rem;
    cursor: pointer;
}

.front-page-product{
    background-color: white;
    max-width: 200px;
    padding: 1rem;
    border-radius: 5px;
}

@media screen and (max-width: 960px) {
    .Header-Img{
        max-width: 100vw;
        max-height: 100vh;
    }

    .Logo {
        height: 6rem;
    }

    .Slogan h1{
        font-size: 2rem;
        margin-bottom: 0px;
    }

    .filtered-products {
        grid-template-columns: repeat(3, 1fr); /* 3 columns */
    }
}

@media (max-width: 600px) {
    .filtered-products {
        grid-template-columns: repeat(2, 1fr); /* 2 columns */
    }
    .front-page-product{
        max-width: 250px;
    }
}

@media (max-width: 400px) {
    .filtered-products {
        grid-template-columns: 1fr; /* 1 column for smaller screens (phone) */
    }
    .front-page-product{
        max-width: 350px;
    }
}